/**
 * Date : 2021-03-21
 * Des: this file conatines currancy helper fucntions 
 */

import { VALID_COUNTRIES } from "../utils/router";


/**
 * get current currancy 
 * @param {*} country 
 * @returns 
 */
export const getCountryCurrency = (country) => {
    if (VALID_COUNTRIES.UAE == country) {
        return 'AED';
    } else if (VALID_COUNTRIES.SA == country) {
        return 'ASR';
    } else if (VALID_COUNTRIES.KW == country) {
        return 'KWD';
    } else if (VALID_COUNTRIES.BD == country) {
        return 'BDT';
    } else if (VALID_COUNTRIES.QA == country) {
        return 'QAR';
    } else if (VALID_COUNTRIES.NG == country) {
        return 'NGN'
    } else if (VALID_COUNTRIES.KE == country) {
        return 'KES'
    } else if (VALID_COUNTRIES.GH == country) {
        return 'GHS'
    } else if (VALID_COUNTRIES.UG == country) {
        return 'UGX'
    } else if (VALID_COUNTRIES.LR == country) {
        return 'LRD'
    } else if (VALID_COUNTRIES.SN == country) {
        return 'XOF'
    } else {
        return 'QAR';
    }
}