import { postShortenLink } from "./common/common";
import { isMobile } from "../utils";
/**
 * handle share urls
 * @param {*} url
 */
export const share = async (url, offer_header) => {
    try {
        // get shorten lin
        const response = await postShortenLink(url);

        // share data
        const shareData = {
            title: offer_header,
            url: response.short_link,
        };

        if (navigator.canShare && navigator.canShare({ url: response.short_link }) && isMobile()) {
            await navigator.share(shareData);
        } else {
            const dummyElement = document.createElement("span");
            dummyElement.style.whiteSpace = "pre";
            dummyElement.textContent = response.short_link;
            document.body.appendChild(dummyElement);

            const selection = window.getSelection();
            selection.removeAllRanges();
            const range = document.createRange();
            range.selectNode(dummyElement);
            selection.addRange(range);

            document.execCommand("copy");

            selection.removeAllRanges();
            document.body.removeChild(dummyElement);
        }
    } catch (err) {
        console.log("err in share", err);
    }
};
