/**
 * Date: 28-03-2021
 * Desc: this file contains common services
 */

import { API } from "../../utils";

export const postShortenLink = async (link) => {
  const response = await API.postRequest(false, "shorten/", {
    link,
  });
  return response;
};

export const recaptcha = ""