import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCountryCurrency } from "../../helpers/currency";
import useTranslation from "../../intl/useTranslation";
import { share } from "../../services/share";
import {
  trackDiscountCardClicked,
  trackOpenDiscountProfile,
  trackShareDiscount,
} from "../../services/tracker/discounts";
import { getRoute, localize } from "../../utils";
import { getPageTypeFromPath, handelPrice, handleExpiredOn } from "../../utils/miscUtils";
import { checkIsListing } from "../../utils/router";
import { Notify } from "../Common";
import Rating from "../rating";

function Discount({ discount, mixPanelTitle, speciality }) {
  // state
  const [openNotify, setOpenNotify] = useState(false);
  const [alertData, setAlertData] = useState({ title: "", type: "", message: "" });
  const { language_code, client_side_country } = useSelector(({ InitialDataS }) => InitialDataS);
  const [isB1G1, setIsB1G1] = useState(discount.type == "buy_one_get_one");

  // get translation
  const { t } = useTranslation();
  const { pathname } = useRouter();

  useEffect(() => {
    setIsB1G1(discount.type == "buy_one_get_one");
  }, [discount]);

  return (
    <React.Fragment>
      <Link href={getRoute(client_side_country, `/discount/${discount?.slug}`)}>
        <a
          className="discount-link"
          style={{ cursor: "pointer" }}
          onClick={() => {
            trackDiscountCardClicked(
              getPageTypeFromPath(pathname),
              discount.offer_header,
              discount.clinic_name,
              speciality || discount?.specialization?.[0] || ""
            );
          }}
        >
          <div className="discount-component">
            <div className="relative">
              <Image
                layout="fill"
                className="img-discount"
                src={discount?.image || "/next-images/discount-pro.png"}
                alt="teeth"
              />
              <div className="discount-img-info">
                <div className="info-left discount-card-status-wrap">
                  {discount?.type === "percentage" ? (
                    <span className="btn-get btn-discounts">
                      <img src="/next-images/icon-discount.svg" alt="%" />
                      {t("Discount")}
                    </span>
                  ) : (
                    <span className="btn-get btn-discounts">
                      <img src="/next-images/icon-discount.svg" alt="%" />
                      {t("Buy1Get1Free")}
                    </span>
                  )}

                  <span className="expires btn-discounts">
                    <img src="/next-images/icon-expire.svg" alt="times-out" />
                    {handleExpiredOn(discount?.expiry_date, t)}
                  </span>
                </div>
                <div className="info-right" style={{ zIndex: "9" }}>
                  <button
                    className="share-discount-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenNotify(true);
                      trackShareDiscount(
                        getPageTypeFromPath(pathname),
                        discount.offer_header,
                        discount.clinic_name,
                        speciality || discount?.specialization?.[0] || ""
                      );
                      share(
                        `${process.env.NEXT_PUBLIC_BASE_URL}${localize(
                          `discount/${discount?.slug}?utm_source=meddy_discounts&utm_medium=direct&utm_campaign=${
                            checkIsListing(pathname) ? "share_listing_" : "share_home_"
                          }${discount?.slug}`,
                          client_side_country,
                          language_code
                        )}`,
                        discount?.offer_header
                      );
                      setAlertData({
                        title: t("Success!"),
                        message: t("Link copied. Now you can share it with a friend!"),
                        type: "success",
                      });

                      e.stopPropagation();
                    }}
                  >
                    <img src="/next-images/icon-share.svg" layout="fill" alt="share-link" />
                  </button>
                </div>
              </div>
            </div>
            <div className="discount-body">
              <div className="discount-info">
                <h4>{discount?.offer_header}</h4>
                <h5>
                  {/* CITY FILTER IS ONLY FOR UAE */}
                  {(client_side_country === "uae"
                    ? `${discount?.city}  ${discount?.address ? `- ${discount?.address}` : ""}`
                    : `${discount?.area}  ${discount?.address ? `- ${discount?.address}` : ""}`
                  )?.slice(0, 38) + "..."}{" "}
                </h5>

                <span className={discount?.reviews_avg == 0 ? "no-reviews" : ""}>
                  <Rating rating={discount?.reviews_avg} numReviews={discount?.reviews_count} showNum={true} />
                </span>
              </div>
              <div className="discount-footer">
                <div className="prices">
                  {!isB1G1 && (
                    <>
                      {" "}
                      <h6 className="sub-text">
                        {getCountryCurrency(client_side_country)} {handelPrice(discount?.price_before)}
                      </h6>
                      <h5 className="main-price">
                        {getCountryCurrency(client_side_country)} {handelPrice(discount?.price_after)}
                      </h5>{" "}
                    </>
                  )}
                  {isB1G1 && (
                    <h5 className="main-price b1g1-price">
                      {getCountryCurrency(client_side_country)} {handelPrice(discount?.price_before)}
                    </h5>
                  )}
                </div>
                <div className="get-discount-container">
                  <button
                    className=" get-discount get-discount-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      trackOpenDiscountProfile(
                        getPageTypeFromPath(pathname),
                        discount.offer_header,
                        discount.clinic_name,
                        speciality || discount?.specialization?.[0] || ""
                      );
                    }}
                  >
                    <h3>{t("Get Discount")}</h3>
                    <h5 style={{ fontSize: "11px", fontWeight: "normal" }}>
                      {!isB1G1 && (
                        <>
                          {t("save")} {getCountryCurrency(client_side_country)}{" "}
                          {Number(handelPrice(discount?.price_before)) - Number(handelPrice(discount?.price_after))}
                        </>
                      )}
                      {isB1G1 && t("Buy 1 Get 1 Free")}
                    </h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </a>
      </Link>
      {openNotify && (
        <Notify
          type={alertData.type}
          message={alertData.message}
          title={alertData.title}
          closeNotification={() => setOpenNotify(false)}
        />
      )}
    </React.Fragment>
  );
}

export default Discount;
